<template>
  <div class="text-nowrap">
    <slot name="other-buttons" />

    <b-button
      v-b-tooltip.hover.v-secondary
      v-b-modal="modal"
      variant="secondary"
      class="btn-icon mr-50"
      size="sm"
      title="Sửa"
      :to="edit"
      :disabled="disableEdit"
      :hidden="hideEdit"
      @click="onEdit"
    >
      <feather-icon icon="EditIcon" />
    </b-button>
    <b-button
      v-b-tooltip.hover.v-danger
      variant="danger"
      class="btn-icon"
      size="sm"
      title="Xoá"
      :disabled="disableDelete"
      :hidden="hideDelete"
      @click="onDeleteClick"
    >
      <feather-icon icon="Trash2Icon" />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BTooltip, VBTooltip, VBModal,
} from 'bootstrap-vue';

export default {
  components: {
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BTooltip,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
    modal: {
      type: String,
      default: null,
    },
    edit: {
      type: Object,
      default: null,
    },
    disableEdit: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    disableDeleteReason: {
      type: String,
      default: null,
    },
  },
  methods: {
    onDeleteClick() {
      if (this.disableDeleteReason && this.disableDeleteReason.length > 0) {
        this.$bvModal
          .msgBoxOk(
            this.disableDeleteReason,
            {
              title: `Xoá ${this.name.toLowerCase()}`,
              okTitle: 'Đóng',
              okVariant: 'warning',
            },
          );
      } else {
        this.$bvModal
          .msgBoxConfirm(
            `Bạn đang thực hiện thao tác xoá ${this.name.toLowerCase()}. Bạn có chắc chắn muốn xoá không?`,
            {
              title: `Xoá ${this.name.toLowerCase()}`,
              okTitle: 'Xoá',
              cancelTitle: 'Huỷ',
              okVariant: 'danger',
              cancelVariant: 'outline-secondary',
            },
          )
          .then(value => {
            if (value) {
              this.$emit('on-delete');
            }
          });
      }
    },
    onEdit() {
      this.$emit('on-edit');
    },
    onPreview() {
      this.$emit('on-preview');
    },
  },
  setup() {},
};
</script>
