<template>
  <b-modal
    id="import-file-modal"
    title="Nhập dữ liệu"
    size="md"
    ok-title="Nhập dữ liệu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-esc
    scrollable
    no-close-on-backdrop
    :ok-disabled="!fileUpload"
    @show="removeFile"
    @hidden="removeFile"
    @ok="onImportFile"
  >
    <div class="content-modal">
      <div class="font-small-2 font-weight-bolder text-uppercase text-primary mb-1">
        1. Tải file dữ liệu mẫu
      </div>

      <small class="text-danger">{{ error }}</small>
      <p
        v-if="errorMessage.length"
        class="text-danger mb-0"
      >
        {{ errorMessage }}
      </p>
      <p>
        File yêu cầu định dạng excel -
        <a
          :href="linkTemplateFile"
          class="link-download-template"
          @click="onDownloadTemplate"
        >
          <feather-icon icon="DownloadIcon" />  Tải file mẫu tại đây
        </a>
      </p>
      <hr>
      <div class="font-small-2 font-weight-bolder text-uppercase text-primary mb-1">
        2. Upload dữ liệu
      </div>
      <div
        :class="['block-action', { drag: isUpload }]"
        @dragover.prevent="dragover"
        @drop.prevent="onDropFile"
        @dragleave="dragleave"
      >
        <feather-icon
          v-if="fileName"
          icon="XCircleIcon"
          class="icon-remove"
          @click="removeFile"
        />
        <input
          id="file-input"
          ref="input"
          type="file"
          name="file-input"
          class="d-none"
          @change="onChangeFile"
        >
        <template v-if="isUpload">
          Thả file tại đây.
        </template>
        <template v-else>
          <div
            v-if="!fileUpload"
            class="d-flex flex-column align-items-center"
          >
            <p>Kéo thả file ở đây</p>
            <p>Hoặc</p>
          </div>
          <div
            v-else
            class="text-file"
          >
            <p class="mb-0">
              {{ fileName }}
            </p>
            <p>
              {{ fileSize }}
            </p>
          </div>

          <label for="file-input"> Chọn File </label>
        </template>
      </div>

    </div>
  </b-modal>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";

export default {
  components: {
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    linkTemplateFile: {
      type: String,
      default: '#',
    },

    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isUpload: false,
      errorMessage: "",
      fileUpload: null,
    };
  },
  computed: {
    fileName() {
      if (!(this.fileUpload instanceof File)) return "";
      return this.fileUpload.name;
    },
    fileSize() {
      if (!(this.fileUpload instanceof File)) return "";
      return this.getFileSize(this.fileUpload.size);
    },
  },
  methods: {
    onDownloadTemplate() {
      this.$emit('download-template-file', { school: this.school, class: this.classObject });
    },
    onImportFile() {
      this.$emit('on-import-file', { file: this.fileUpload, apartment: this.apartment });
    },
    dragover() {
      if (!this.fileName) this.isUpload = true;
    },
    dragleave() {
      if (!this.fileName) this.isUpload = false;
    },
    onDropFile(event) {
      const { files } = event.dataTransfer;
      this.handleUploadFile(files);
    },
    onChangeFile({ target }) {
      const { files } = target;
      if (!files || !files.length) return;
      this.handleUploadFile(files);
    },
    handleUploadFile(files) {
      if (!files || !files.length) return;
      const file = files[0];
      if (!file.name.toLowerCase().match(/.*\.(xlsx|xls|xltx)/g)) {
        this.removeFile();
        this.errorMessage = "file không đúng định dạng!";
      } else {
        this.errorMessage = "";
        this.$refs.input.files = files;
        this.fileUpload = file;
      }
      this.isUpload = false;
    },
    removeFile() {
      if (this.$refs.input) {
        this.$refs.input.value = "";
      }
      this.isUpload = false;
      this.errorMessage = "";
      this.fileUpload = null;
    },
    getFileSize(size, decimal = 1) {
      const BYTES = 1024;
      if (size >= BYTES ** 2) {
        const val = (size / BYTES ** 2).toFixed(decimal);
        return `${val}MB`;
      }
      if (size >= BYTES) {
        const val = (size / BYTES).toFixed(decimal);
        return `${val}KB`;
      }
      const val = size.toFixed(decimal);
      return `${val}B`;
    },
  },
};
</script>

<style lang="scss" scoped>
.block-action {
  position: relative;
  border: 1px dashed grey;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  height: 150px;
  border-radius: 5px;

  &.drag {
    background-color: rgba(128, 128, 128, 0.616);
    color: white;
    border-color: white;
  }

  label {
    background: #248f55;
    padding: 5px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    box-shadow: 0 0 5px #00000040;

    &:hover {
      box-shadow: 0 0 0;
    }
  }
}
.link-download-template {
  color: #248f55;
  cursor: pointer;
}
.icon-remove {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.text-file {
  p {
    text-align: center;

    &:first-child {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
