import { computed, ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import zaloAccountStoreModule from '../zaloAccountStoreModule';

export default function useZaloAccountLoginModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'zaloAccount';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, zaloAccountStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModalZaloAccountLogin = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const isLoading = ref(false);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const resetModal = () => {
    resetItemLocal();
  };
  const loginQrCode = ref(null);
  const loginStatus = ref('scan');
  const loginErrorDescription = ref(null);
  const itemId = computed(() => props.item.id);
  const receivePhoneNumber = ref(null);

  const sendTestMessage = () => {
    if (!receivePhoneNumber.value) {
      toastification.showToastErrorWithMessage('Vui lòng cung cấp số điện thoại nhận tin nhắn test');
      return;
    }
    if (itemId && itemId.value > 0) {
      store
        .dispatch('zaloAccount/sendTestMessage', { zaloAccountId: itemId.value, receivePhoneNumber: receivePhoneNumber.value })
        .then(() => {
          toastification.showToastSuccess('Vui lòng đợi trong giây lát để BOT gửi tin nhắn. Nếu bạn không nhận được trong vòng 1 phút thì có thể đã có vấn đề xảy ra');
        })
        .catch(error => {
          toastification.showToastError(error);
        });
    }
  };

  const startLoginProcess = () => {
    console.log(itemId.value);
    if (itemId && itemId.value > 0) {
      store
        .dispatch('zaloAccount/startLoginProcess', props.item.id)
        .then(() => {
          emit('refetch-data');
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = false;
        });
    }
  };

  const handleZaloProcess = receivedData => {
    const { status, base64ImageString, description } = receivedData;
    if (base64ImageString && base64ImageString.length > 0) {
      loginQrCode.value = base64ImageString;
    }
    if (status) {
      loginStatus.value = status;
    }
    loginErrorDescription.value = description;
    emit('refetch-data');
  };

  const onOpen = () => {
    resetItemLocal();
    startLoginProcess();
  };

  return {

    refModalZaloAccountLogin,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    sendTestMessage,
    onOpen,
    loginQrCode,
    loginStatus,
    handleZaloProcess,
    loginErrorDescription,
    receivePhoneNumber,
  };
}
