<template>
  <b-modal
    id="modal-zaloAccount"
    ref="refModal"
    title="Tài khoản Zalo"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Tên tài khoản <span class="text-danger">(*)</span>
                </template>
                <b-input-group>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    placeholder="Hotline CSKH"
                    trim
                    :state="getValidationState(validationContext)"
                  />
                </b-input-group>
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group
                label="'Số điện thoại'"
              >
                <template
                  v-slot:label
                >
                  Số điện thoại <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  placeholder="0868987355"
                  trim
                  :state="getValidationState(validationContext)"
                  :disabled="itemLocal.id && itemLocal.statusObject && itemLocal.statusObject.id === 'connected' "
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Địa chỉ -->

          <!-- submit -->
        </b-row>

      </b-form>
    </validation-observer>

  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BInputGroup,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useZaloAccountModal from './useZaloAccountModal';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BInputGroup,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      isSubmitting,
      onSubmit,
    } = useZaloAccountModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
./useZaloAccountModal
