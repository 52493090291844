<template>
  <b-modal
    id="modal-zalo-account-login"
    ref="refModalZaloAccountLogin"
    title="Đăng nhập Zalo"
    size="lg"
    ok-title="Đóng"
    ok-variant="secondary"
    ok-only
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >
    <!-- Body -->

    <b-row>
      <b-col
        cols="12"
      >
        <b-alert
          show
          :variant="loginStatus === 'success' ? 'success' : loginStatus === 'scan' ? 'warning' : 'danger'"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="StarIcon"
            />
            <span class="ml-25">{{ loginStatus === 'scan' ? 'Để đăng nhập, vui lòng quét mã QRCode bên dưới và xác nhận đăng nhập trên điện thoại': loginStatus === 'success' ? 'Tuyệt vời! Tài khoản của bạn đã liên kết và đăng nhập thành công' : 'Có lỗi trong việc khởi tạo Profile đăng nhập, vui lòng thử lại sau 1 phút nhé.' }}</span>
          </div>
        </b-alert>
      </b-col>

      <b-col>
        <b-list-group>
          <b-list-group-item v-if="loginStatus === 'scan'">Bước 1: Mở ứng dụng <strong>Zalo</strong> trên thiết bị di động, sau đó quét mã QRCode bên dưới (Giữ việc quét QRCode cho đến khi hiện xác nhận trên điện thoại):
            <br><br><br>
            <b-overlay
              :show="loginQrCode == null || loginQrCode.length === 0"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              class="d-inline-block"
            >
              <div style="width: 150px; height: 150px;">
                <b-img
                  :src="loginQrCode"
                  width="150px"
                  height="150px"
                />
              </div>

            </b-overlay>
          </b-list-group-item>

          <b-list-group-item
            v-if="loginStatus === 'scan'"
          >Bước 2: Sau khi quét mã, vui lòng ấn Đồng ý đăng nhập trên điện thoại</b-list-group-item>
          <b-list-group-item v-if="loginStatus === 'success'">
            <div>Đã kết nối thành công và đăng nhập vào tài khoản Zalo. Bạn có thể ấn nút bên dưới để gửi thử 1 tin nhắn để đảm bảo rằng BOT đã hoạt động.</div>

            <b-form-group label="Số điện thoại nhận tin nhắn">
              <b-input-group>
                <b-form-input
                  v-model="receivePhoneNumber"
                  placeholder="0868987355"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-success"
                    :disabled="!receivePhoneNumber || receivePhoneNumber.length === 0"
                    @click="sendTestMessage"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="mr-50"
                    />
                    Gửi tin nhắn
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

          </b-list-group-item>
          <b-list-group-item
            v-if="loginStatus === 'fail' && loginErrorDescription"
          >Mô tả lỗi: {{ loginErrorDescription }}</b-list-group-item>

        </b-list-group>
      </b-col>

    </b-row>

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BAlert,
  BListGroup,
  BListGroupItem,
  BImg,
  BButton,
  BOverlay,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue';
import useZaloAccountLoginModal from './useZaloAccountLoginModal';

export default {
  components: {
    BModal,
    BCol,
    BRow,
    BAlert,
    BListGroup,
    BListGroupItem,
    BImg,
    BButton,
    BOverlay,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    };
  },
  mounted() {
    // this.$socket.connect();
    // this.$socket.emit('join', {});
  },
  setup(props, { emit }) {
    const {
      refModalZaloAccountLogin,
      refForm,
      itemLocal,
      resetItemLocal,
      resetModal,
      sendTestMessage,
      onOpen,
      loginQrCode,
      loginStatus,
      handleZaloProcess,
      loginErrorDescription,
      receivePhoneNumber,
    } = useZaloAccountLoginModal(props, emit);

    return {
      refModalZaloAccountLogin,
      refForm,
      itemLocal,
      resetItemLocal,
      resetModal,
      sendTestMessage,
      onOpen,
      loginQrCode,
      loginStatus,
      handleZaloProcess,
      loginErrorDescription,
      receivePhoneNumber,
    };
  },
  sockets: {
    loginZalo(data) {
      this.handleZaloProcess(data);
    },
  },
};
</script>
./useZaloAccountLoginModal
